.bdark,
.bgreen,
.blight {
    border: 2px solid var(--color-grey);
    margin: 1rem;
    border: 1px solid #191A23;
    box-shadow: 0px 5px 0px #191A23;
    border-radius: 45px;
    align-items: center;
    padding: 2rem;
}

.box {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    min-height: 210px;
}

.bdark {
    background: var(--color-dark);
}

.bgreen {
    background: var(--color-highlight);
}

.blight {
    background: var(--color-grey);
}

.bdark span,
.bgreen span,
.blight span {
    display: table;
    padding: .1rem .5rem;
    font-weight: 500;
    font-size: 26px;
    line-height: 28px;
    border-radius: 5px;
    
}

.blight span {
    background: var(--color-green);
    color: var(--color-grey);
}

.bgreen span {
    background: var(--color-grey);
}

.bdark span {
    background: var(--color-grey);
}

.readmore {
    color: var(--color-dark);
    font-size: 20px;
    text-decoration: none;
    margin-top: 1rem;
}

.readmore svg {
    font-size: 26px;
    margin-right: .5rem;
    color: var(--color-dark);
}

.bdark .readmore,
.bdark .readmore svg {
    color: var(--color-grey);
}

.readmore:hover{
transform: scale(1.1);
color: var(--color-dark);
}


.bdark .readmore:hover{
    transform: scale(1.1);
    color: var(--color-grey);
} 

@media(max-width: 768px) {
    .img-services{ 
        display: none;
    }
}