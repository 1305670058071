.community-links-container {
  display: flex;
  justify-content: space-around;
}

.community-links {
  display: flex;
  gap: 90px;
  display: flex;
  justify-content: space-around;
  padding: 20px;
}

.text {
  flex: 1;
  margin-right: 20px;
}

.community-link {
  text-align: center;
  color: black;
  padding: 5px;
}

.icon-link {
  display: inline-block;
  color: inherit;
  text-decoration: none;
}

.discord-icon:hover,
.youtube-icon:hover {
  transform: scale(1.1);
}
