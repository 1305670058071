.intro-text {
    margin-top: 4rem;
    margin-bottom: 4rem;
}

.img-fluid {
    border-radius: 10px;
}

.btn-left {
    margin-right: 1rem;
}