.navbar{
    padding: 40px 0px;
}

.nav-link {
    color: var(--color-text);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    margin: .4rem; 
}

.nav-link:last-child {
    border: 1px solid;
    border-radius: 5px;
    margin-left: 10px;
}

.nav-link:hover {
    background: var(--color-green);
    border-radius: 5px;
    color: var(--color-text-light);
}

.nav-link:last-child:hover {
    background: var(--color-green);
    color: var(--color-text-light);
}

.btn-logout {
    border-radius: 14px;
}

.btn-language-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    margin-top: 5px;
    margin-left: 10px;
}

.btn-language {
    border: none;
    background: #fff;
}

.btn-language-selected {
    border: 1px solid;
    border-radius: 14px;
}

@media(max-width: 991px) {

    .navbar-collapse,
    .navbar-nav {
        text-align: right;
    }

    .nav-link {
        margin: .3rem 0;
        padding: .5rem;
    }

    .nav-link:last-child {
        background: var(--color-green);
        color: var(--color-text-light);
        text-align: center;
    }
}

.navbar-toggler{
    border: none;
    padding-top: .8rem;
    text-align: center;
    margin-bottom: 1rem;
}
.navbar-toggler-icon{
    background: url(../../assets/menu.png) no-repeat;
}