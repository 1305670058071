.speech-bubble {
	position: relative;
	border: 1px solid var(--color-green);
	background: var(--color-green);
	border-radius: .4em;
	max-width: 600px;
	margin: 0 auto;
	padding: 15px;
	color: white;
}

.speech-bubble:after {
	content: '';
	position: absolute;
	bottom: 0;
	left: 20%;
	width: 0;
	height: 0;
	border: 36px solid transparent;
	border-top-color: var(--color-green);
	border-bottom: 0;
	border-right: 0;
	margin-left: -18px;
	margin-bottom: -36px;
}

.testimonial-info {
	margin-left: 110px;
	margin-top: 30px;
	display: flex;
	align-items: center; 
}

.testimonial-image {
	width: 100px; /* Adjust width as needed */
	height: auto; /* Maintain aspect ratio */
	margin-right: 20px; /* Space between image and text */
}

.testimonial-name {
	color: var(--color-green);
	font-weight: 500;
	font-size: 20px;
	text-align: center;
	display: flex;
	flex-direction: column;
	width: 300px;
}

.testimonial-control-next,
.testimonial-control-prev {
	align-items: end;
}

.testimonials-container {
  display: flex;
  flex-wrap: wrap;
	flex-direction: row;
  justify-content: center;
  gap: 40px;
}

@media(max-width: 1199px) {
	.testimonials-container {
    flex-direction: column;
    align-items: center;
  }

	.speech-bubble {
		max-width: 100%;
		width: 100%;
		margin-right: 10px;
	}
}

@media(max-width: 800px) {
	.testimonials-container {
    flex-direction: column;
    align-items: center;
  }

	.speech-bubble {
		max-width: 100%;
		width: 100%;
	}

	.testimonial-image {
		width: 50px; /* Adjust width as needed */
	}

	.testimonial-name {
		color: var(--color-green);
		font-weight: 500;
		font-size: 16px;
		text-align: center;
		display: flex;
		flex-direction: column;
		max-width: 200px;
		margin-left: 35px;
	}

	.testimonial-info {
		margin-left: 30px;
		margin-top: 30px;
		display: flex;
		align-items: center; 
	}
}

