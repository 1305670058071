.clients {
    justify-content: flex-start;
}

.partner-container {
    margin-top: 60px;
    text-align: center;
    font-size: 16px;
}

.tscroll {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(120px, 250px));
    gap: 60px;
    justify-items: center;
    align-items: center;
    margin-bottom: 40px;
    justify-content: center; /* Ensures items are centered within the container */
}

.tscroll img {
    max-width: 75%;
    height: auto;
    object-fit: contain;
}

.tscroll:after {
    content: '';
    width: 120px; /* Match your minmax() size */
    height: 0;
}

.icon-scroll-image {
    border-radius: 20px;
}

@media (min-width: 480px) {
    .tscroll {
        grid-template-columns: repeat(3, 1fr);
    }
    .tscroll:after {
        content: '';
        width: 0; /* Prevent the pseudo-element from affecting layouts smaller than your breakpoints */
    }
}

@media (min-width: 768px) {
    .tscroll {
        grid-template-columns: repeat(4, 1fr);
    }
}

@media (min-width: 992px) {
    .tscroll {
        grid-template-columns: repeat(5, 1fr);
    }
}
