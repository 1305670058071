footer {
    background: var(--color-dark);
    margin-top: 3rem;
    padding: 3rem;
    border-top-right-radius: 45px;
    border-top-left-radius: 45px;
    color: #fff;
}

ul.navbar-footer li {
    margin: 0 1rem;
}

ul.navbar-footer li a {
    color: var(--color-dark);
    color: #fff;

}

.info-contact li {
    margin: .8rem 0;
    padding: 0;
}


footer .btn-green{
    background: var(--color-green);
    color:var(--color-dark);
    font-size: 16px;
    font-weight: 500;

}


.rights {

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    border-top: 1px solid #FFFFFF;
    padding-top: 3rem;
}

.rights a {
    color: #fff;
    margin-left: 3rem;
}

@media(max-width: 768px) {
    footer {
        text-align: center;
        padding: 3rem 1.5rem 2rem;
    }

    .navbar-footer li a {
        text-decoration: none;
        margin: .5rem auto;
        display: block;

    }

    .rights {
        flex-direction: column;
        text-align: center;
    }

    .rights a {

        margin-left: 0;
    }

    .rights {
        padding-top: 3rem;
        margin-top: 3rem;
    }
}