.checkmark {
    color: green;
    margin-right: 0.5rem;
}

.bullet-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* This will create two equal-width columns */
    gap: 1rem; /* This will add space between the bullets */
    padding: 2rem 0rem 2rem;
    padding-bottom: 5rem;
}

