.usp-component {
  width: 100%;
  height: 100%;
}

.usp-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: #ffffff;
  border: 1px solid #191a23;
  box-shadow: 0px 5px 0px #191a23;
  border-radius: 45px;
  padding: 3rem 3rem 3rem;
}

.usp-team-header {
  border-bottom: 1px solid #000;
  margin-bottom: 1rem;
}

.usp-team-header img {
  width: 28px;
  height: 26px;
  margin-bottom: 6px;
}

.team-image {
  width: 160px !important;
  height: 160px !important;
}

.usp-team-header img {
  width: 28px;
  height: 26px;
  margin-bottom: 6px;
}

.usp-team-header span {
  display: block;
  font-weight: 700;
}

.usp-team-header a {
  align-self: flex-start;
  align-items: end;
}

.team-team-header a svg {
  font-size: 20px;
  color: #000;
  background: var(--color-green);
}

.team-team-header a svg:hover {
  background: #ffffff;
  transform: scale(1.1);
}

.team-team p {
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  padding: 0 0.5rem;
}

h3 {
  color: #509d72;
}

.card-usp-container {
  display: flex;
  flex-wrap: wrap;
  background: #ffffff;
  border: 1px solid #191a23;
  box-shadow: 0px 5px 0px #191a23;
  border-radius: 45px;
  margin: 3rem 0rem;
  min-height: 260px;
}

.card-usp-johannes,
.card-usp-CTA {
  padding: 3rem 3rem 3rem;
  width: 50%;
}

.btn-green {
  margin-bottom: 2rem;
}

@media (max-width: 768px) {
  .card-usp-container {
    flex-direction: column;
  }
  .card-usp-johannes,
  .card-usp-CTA {
    width: 100%;
  }

  .usp-container {
    height: 100%;
    padding: 3rem 3rem 3rem;
    max-width: 100%;
  }
}
