.popup-content {
  background-color: #fff;
  padding: 40px;
  border-radius: 30px;
  overflow: hidden;
  max-width: 800px;
}

.email-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
}

.error-message {
  color: red;
}

.popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.close-button {
  padding: 8px 15px;
  margin-top:-30px;
  margin-right:-10px;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  background: var(--color-red);
  border-radius: 14px;
  border: none;
  color: #fff;
  transition: all 0.3s ease-in-out;
}

.close-button:hover {
  transform: scale(1.05);
}

.popup-text {
  padding-right: 10%;
}

.popup-link {
  font-size: 20px;
}