
:root {
    --font-base: 'Montserrat', sans-serif;
    --color-text-dark: #000000;
    --color-text-light: #ffffff;
    --color-highlight: #94E2B9;
    --color-green: #509D72;
    --color-dark: #191A23;
    --color-grey: #F3F3F3;
    --color-red: #e77f7f;
}

* {
    font-family: var(--font-base);
}

.section-padding {
    margin: 50px 0;
}

@media(max-width: 768px) {
    .section-padding {
        margin: 5px;
    }  
}

h1,
h2,
h3,
h4 {
    color: var(--color-text-dark);
    font-weight: 500;
}

h3 {
    font-size: 30px;
    line-height: 38px;
}

h4 {
    font-size: 20px;
    line-height: 26px;
}

.title {
    font-size: 60px;
    line-height: 77px;
}

@media(max-width: 768px) {
    .title {
        font-size: 43px;
        line-height: 55px;
    }
}

ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

input[type="email"],
input[type="text"] {
    border-radius: 14px;
    padding: 15px;
}

.btn-green {
    padding: 15px 35px;
    margin-top:20px;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    background: var(--color-green);
    border-radius: 14px;
    border: none;
    color: #fff;
    transition: all 0.3s ease-in-out;
}

.btn-green:hover {
    transform: scale(1.05);
}

.btn-green-small {
    padding: 6px 17px;
    margin-top:20px;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    background-color: #ffffff;
    border-radius: 14px;
    border: 1px solid;
    border-radius: 5px;
    margin-left: 10px;
    transition: all 0.3s ease-in-out;
}

.btn-green-small:hover {
    transform: scale(1.05);
}

@media(max-width: 768px) {
    .btn-green {
        padding: 15px;
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
    }
}

p {
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
}

span {
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;}

.grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(60px, 1fr));
}

.grid-item {
}
.dot::before {
    content: "";
    height: 10px;
    width: 10px;
    background-color: #94E2B9;
    border-radius: 50%;
    display: inline-block;
    margin-right: 10px;
}
.star {
    color: #F4EA07;
}

.rounded-corners {
    border-radius: 20px;
}

.video-container {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%; /* 16:9 Aspect Ratio */
}

.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

@media (min-width: 768px) {
    .video-container {
        width: 560px; /* or whatever width you want */
        height: 315px; /* or whatever height you want */
        padding-bottom: 0;
    }

    .video-container iframe {
        position: static;
        width: 100%;
        height: 100%;
    }
}